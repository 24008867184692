import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import PageLoading from './components/PageLoading';

import Layout from './components/Layout';
const Home = lazy(() => import('./pages/Home'));
const Projects = lazy(() => import('./pages/Projects'));
const ProjectDetails = lazy(() => import('./pages/ProjectDetails'));
const InvestmentContainer = lazy(() => import('./pages/InvestmentContainer'));
const MyInvestments = lazy(() => import('./pages/MyInvestments'));
const Account = lazy(() => import('./pages/Account'));
const HowItWorks = lazy(() => import('./pages/HowItWorks'));
const Error404 = lazy(() => import('./pages/Error404'));
const ConstructionPage = lazy(() => import('./pages/Constrution'));
const Profile = lazy(() => import('./pages/Profile'));
const TermsConditions = lazy(() => import('./pages/TermsConditions'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const FAQIndex = lazy(() => import('./pages/FAQIndex'));
const FAQCategory = lazy(() => import('./pages/FAQCategory'));
const FAQDetail = lazy(() => import('./pages/FAQDetail'));
const ExposedPersonPolicy = lazy(() => import('./pages/ExposedPersonPolicy'));
const Products = lazy(() => import('./pages/Products'));
const ProductTypes = lazy(() => import('./pages/ProductTypes'));

import useRecoilDebugger from './hooks/useRecoilDebugger';
import useAuthRecoil from './hooks/useAuthRecoil';
import {
  HistoryListener,
  NavigationConfirmModal,
} from 'react-router-navigation-confirm';
import PropTypes from 'prop-types';

const App = () => {
  useRecoilDebugger();

  const {
    auth: { isAuth },
  } = useAuthRecoil();

  // eslint-disable-next-line react/prop-types

  return (
    <Router>
      <Layout>
        <>
          <HistoryListener>
            <NavigationConfirmModal
              confirmText={'Continuar'}
              cancelText={'Cancelar'}
              buttonConfirmClassName={'cancel-button'}
              when={(...args) => {
                if (args[0].state?.update === true) {
                  return true;
                }
                return false;
              }}
            >
              <div>
                <h2>¿Esta seguro que desea salir?</h2>
                <br />
                <p>Los datos sin guardar se perderán</p>
              </div>
            </NavigationConfirmModal>
          </HistoryListener>
        </>
        <Suspense fallback={<PageLoading />}>
          <Switch>
            <Route path="/proyectos-inmobiliarios/:id/inversion">
              <InvestmentContainer />
            </Route>

            <Redirect
              from="/proyecto_inmobiliario"
              to="/proyectos-inmobiliarios/"
            />

            <Redirect
              exact
              from="/proyectos-inmobiliarios/proyecto/"
              to="/proyectos-inmobiliarios/"
            />

            <Route exact path="/productos">
              <Products />
            </Route>

            <Route exact path="/productos/:productType">
              <ProductTypes />
            </Route>

            <Route exact path="/proyectos-inmobiliarios/:filters?">
              <Projects />
            </Route>

            <Route exact path="/proyectos-inmobiliarios/proyecto/:slug">
              <ProjectDetails />
            </Route>

            <ProtectedRoute
              path="/mi-perfil/inversiones/:slug?"
              isAuth={isAuth}
            >
              <MyInvestments />
            </ProtectedRoute>

            {isAuth && (
              <Route path="/mi-perfil">
                <Profile />
              </Route>
            )}

            <Route path="/terminos-y-condiciones">
              <TermsConditions />
            </Route>

            <Route path="/politica-privacidad">
              <PrivacyPolicy />
            </Route>

            <Route path="/preguntas-frecuentes/:categorySlug/:questionSlug">
              <FAQDetail />
            </Route>

            <Route path="/preguntas-frecuentes/:categorySlug">
              <FAQCategory />
            </Route>

            <Route exact path="/preguntas-frecuentes">
              <FAQIndex />
            </Route>

            <Route path="/politica-persona-expuesta-politicamente">
              <ExposedPersonPolicy />
            </Route>

            <Route path="/como-funciona">
              <HowItWorks />
            </Route>

            <Route path="/cuenta">
              <Account />
            </Route>

            <Route path="/proyectos-inmobiliarios">
              <Projects />
            </Route>

            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/construccion">
              <ConstructionPage />
            </Route>

            <Route>
              <Error404 />
            </Route>
          </Switch>
        </Suspense>
      </Layout>
    </Router>
  );
};

export default App;

const ProtectedRoute = ({ isAuth, children, ...rest }) => {
  const location = useLocation();
  if (!isAuth) {
    localStorage.setItem('redirectAfterLogin', location.pathname);
    return <Redirect to="/cuenta" />;
  }
  return <Route {...rest}>{children}</Route>;
};

ProtectedRoute.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
