import { DropdownItem, HStack, Label } from '../SimpleUI';
import React from 'react';
import {
  HiOutlineCurrencyDollar,
  HiOutlineLogout,
  HiOutlineUserCircle,
} from 'react-icons/hi';
import { IoPeopleCircleOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { theme } from '../../theme';
import PropTypes from 'prop-types';

const MenuDropdown = ({ handleLogout, toggle }) => {
  const history = useHistory();
  return (
    <>
      <DropdownItem
        onClick={() => {
          toggle();
          history.push('/mi-perfil');
        }}
      >
        <Label tag="p" color="textBlack" fontWeight="regular">
          <HStack tag="span" space="small" alignItems="center">
            <HiOutlineUserCircle size="18" color={theme.colors.secondary} />{' '}
            <span>Mi cuenta</span>
          </HStack>
        </Label>
      </DropdownItem>
      <DropdownItem onClick={handleLogout}>
        <Label tag="p" color="textBlack" fontWeight="regular">
          <HStack tag="span" space="small" alignItems="center">
            <HiOutlineLogout size="18" color={theme.colors.secondary} />{' '}
            <span>Cerrar sesión</span>
          </HStack>
        </Label>
      </DropdownItem>
    </>
  );
};

MenuDropdown.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default MenuDropdown;
