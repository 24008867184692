import React from 'react';
import { Small, Box } from '../SimpleUI';
import NotificationImage from '../../assets/images/notification-ico.svg';
import useGetNotifications from '../../hooks/useGetNotifications';
import Skeleton from 'react-loading-skeleton';
import useNotificationMenuRecoil from '../../hooks/useNotificationMenuRecoil';
import useSetHiddenOverflow from '../../hooks/useSetHiddenOverflow';

const Notifications = () => {
  const {
    toggleNotifications,
    showNotifications,
  } = useNotificationMenuRecoil();

  const { loading, data } = useGetNotifications();
  useSetHiddenOverflow(showNotifications, toggleNotifications);

  if (loading) {
    return <Skeleton width={32} height={32} />;
  }

  return (
    <Box
      position="relative"
      cursor="pointer"
      display="flex"
      onClick={() => toggleNotifications()}
    >
      {data?.getNotifications.notifications?.some((item) => !item.isRead) && (
        <Box
          position="absolute"
          width="10px"
          height="10px"
          backgroundColor="red"
          top="-2px"
          right="-2px"
          zIndex="1"
          borderRadius="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
        ></Box>
      )}
      <Box tag="img" src={NotificationImage} alt="your notifications" />
    </Box>
  );
};

export default Notifications;
