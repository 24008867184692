import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Label from '../Typography/Label';
import VStack from '../Stack/VStack';
import Small from '../Typography/Small';
import useDesignUtils from '../../../hooks/useDesignUtils';

import { FiEye, FiEyeOff } from 'react-icons/fi';
import DatePicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components';
import { subYears } from 'date-fns';
import es from 'date-fns/locale/es';
registerLocale('es', es);

const InputBase = ({
  tag,
  name,
  type,
  label,
  placeholder,
  value,
  onChange,
  error,
  disabled,
  size,
  borderColor,
  keyboardType,
  minLength,
  maxLength,
  borderWidth = '1px',
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const { resolveThemeProps, theme } = useDesignUtils();
  const sizeValue = resolveThemeProps('inputSizes', size);

  const handleInputChange = (event) => {
    const numberRegex = /^[0-9]*\.?[0-9]*$/;

    if (keyboardType === 'number') {
      if (event.target.value === '' || numberRegex.test(event.target.value)) {
        onChange(event);
      }
    } else {
      onChange(event);
    }
  };

  return (
    <Box flexGrow="0" flexShrink="1" flexBasis={sizeValue}>
      <VStack space="xsmall">
        {label && (
          <Label
            color={restProps.labelColor}
            size={restProps.labelSize}
            htmlFor={name}
            fontWeight="medium"
          >
            {label}
          </Label>
        )}
        <Box position="relative">
          {type === 'date' && tag === 'input' ? (
            <StyledDateInput
              locale="es"
              dateFormat="dd/MM/yyyy"
              selected={value}
              onChange={(date) => onChange({ name, date })}
              error={error}
              disabled={disabled}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/aaaa"
              maxDate={subYears(new Date(), 18)}
              minDate={subYears(new Date(), 100)}
            />
          ) : (
            <Box
              tag={tag}
              type={showPassword ? 'text' : type}
              id={name}
              name={name}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              onChange={handleInputChange}
              borderRadius="small"
              width="100%"
              height="45px"
              borderWidth={borderWidth}
              borderStyle="solid"
              padding="small"
              borderColor={error ? 'red' : borderColor}
              maxLength={maxLength}
              minLength={minLength}
              {...restProps}
            />
          )}

          {type === 'password' && (
            <Box
              tag="button"
              type="button"
              onClick={() => setShowPassword((prev) => !prev)}
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              right="5px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              tabIndex={-1}
            >
              {showPassword ? (
                <FiEyeOff size={22} color={theme.colors.gray} />
              ) : (
                <FiEye size={22} color={theme.colors.gray} />
              )}
            </Box>
          )}
        </Box>
        {error && (
          <Small tag="p" color="red" fontWeight="medium">
            {error}
          </Small>
        )}
      </VStack>
    </Box>
  );
};

const StyledDateInput = styled(DatePicker)`
  width: 100%;
  height: 45px;
  padding-top: ${({ theme }) => theme.scale.small};
  padding-bottom: ${({ theme }) => theme.scale.small};
  padding-left: ${({ theme }) => theme.scale.small};
  padding-right: ${({ theme }) => theme.scale.small};
  border-top-color: ${({ theme, error }) =>
    error ? theme.colors.red : theme.colors.primary};
  border-bottom-color: ${({ theme, error }) =>
    error ? theme.colors.red : theme.colors.primary};
  border-right-color: ${({ theme, error }) =>
    error ? theme.colors.red : theme.colors.primary};
  border-left-color: ${({ theme, error }) =>
    error ? theme.colors.red : theme.colors.primary};
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.radius.small};
  display: block;
  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }
`;

InputBase.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'full'])
    .isRequired,
  tag: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  keyboardType: PropTypes.oneOf(['number', 'text']),
  borderColor: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  borderWidth: PropTypes.string,
};

export default InputBase;
