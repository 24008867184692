import { useRecoilState } from 'recoil';
import { dashboardState, initialState } from '../recoil/dashboard';

const useDashboardRecoil = () => {
  const [newDashboardValues, setDashboardValues] = useRecoilState(
    dashboardState,
  );

  const setDashboardFormData = (values) => {
    setDashboardValues((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
  };

  const resetDashboard = () => {
    setDashboardValues(initialState);
  };

  return {
    setDashboardFormData,
    resetDashboard,
    newDashboardValues,
  };
};

export default useDashboardRecoil;
