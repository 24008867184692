import { useEffect } from 'react';

const useSetHiddenOverflow = (isOpen, callback) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
      const handleEsc = (event) => {
        if (callback && event.keyCode === 27) {
          callback();
        }
      };
      window.addEventListener('keydown', handleEsc);
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    } else {
      if (document.body.classList.contains('overflow-hidden')) {
        document.body.classList.remove('overflow-hidden');
      }
    }
  }, [isOpen, callback]);
};

export default useSetHiddenOverflow;
