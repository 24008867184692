import { atom } from 'recoil';
import localStorageEffect from './localStorageEffect';

export const initialState = {
  wallet: [],
  dataQuotation: {},
};

export const dashboardState = atom({
  key: 'dashboardState',
  default: initialState,
  effects_UNSTABLE: [localStorageEffect('dashboard')],
});
