import Box from './Box';
import Heading from './Typography/Heading';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { use100vh } from 'react-div-100vh';
import { HiOutlineXCircle } from 'react-icons/hi';
import useDesignUtils from '../../hooks/useDesignUtils';

const Modal = ({
  handleClose,
  isOpen = false,
  contentSpace = true,
  children,
  width,
  color = 'white',
  title,
  center = '0%',
}) => {
  const height = use100vh();
  const resolveWidth = typeof width === 'number' ? `${width}px` : 'auto';
  const { isMobile } = useDesignUtils();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('setHidden');
      const handleEsc = (event) => {
        event.keyCode === 27 && handleClose();
      };
      handleClose && window.addEventListener('keydown', handleEsc);
      return () => {
        handleClose && window.removeEventListener('keydown', handleEsc);
      };
    } else {
      if (document.body.classList.contains('setHidden')) {
        document.body.classList.remove('setHidden');
      }
    }
  }, [handleClose, isOpen]);

  const animationVariants = {
    open: { opacity: 1, y: '5%', x: '-50%' },
    closed: { opacity: 0, y: '20%', x: '-50%' },
  };

  if (isOpen) {
    return (
      <Box
        tag="article"
        position="fixed"
        width="100%"
        height={height}
        top="0"
        left="0"
        zIndex="1050"
        overflowY="auto"
      >
        <motion.div
          style={{
            opacity: 0,
            x: '-50%',
            y: '0%',
            position: 'absolute',
            top: center,
            left: '50%',
            zIndex: '2',
            backgroundColor: color,
            borderRadius: '20px',
            width: isMobile ? '95%' : resolveWidth,
          }}
          animate={isOpen ? 'open' : 'closed'}
          transition={{
            duration: 0.15,
          }}
          variants={animationVariants}
        >
          <Box
            tag="article"
            paddingTop={contentSpace ? 'xxxlarge' : 'none'}
            paddingBottom={contentSpace ? 'xlarge' : 'none'}
            paddingX={
              contentSpace ? (isMobile ? 'standard' : 'xlarge') : 'none'
            }
            position="relative"
          >
            {title && (
              <Box
                tag="header"
                top="12.5px"
                left={contentSpace ? '32px' : '10px'}
                position="absolute"
              >
                <Heading fontWeight="medium">{title}</Heading>
              </Box>
            )}
            {handleClose && (
              <Box
                tag="span"
                cursor="pointer"
                onClick={handleClose}
                position="absolute"
                top="12.5px"
                right="10px"
              >
                <Heading
                  fontWeight="medium"
                  color={color === 'purple' ? 'white' : 'black'}
                >
                  <HiOutlineXCircle size={26} />
                </Heading>
              </Box>
            )}
            {children}
          </Box>
        </motion.div>
        <Box
          display="block"
          width="100%"
          height="100%"
          position="fixed"
          top="0"
          left="0"
          backgroundColor="lightBlack"
          zIndex="1"
        />
      </Box>
    );
  }

  return null;
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  width: PropTypes.number,
  variant: PropTypes.oneOf(['purple', 'white']),
  contentSpace: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string,
  center: PropTypes.string,
};

export default Modal;
