import React from 'react';
import { Box, SmallCopy, HStack } from '../SimpleUI';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import useSetHiddenOverflow from '../../hooks/useSetHiddenOverflow';

const Toggler = ({ onClick, isOpen }) => {
  useSetHiddenOverflow(isOpen, onClick);
  return (
    <Box
      tag="button"
      onClick={onClick}
      aria-label="Toggle navigation"
      paddingY="small"
      paddingX="medium"
      backgroundColor="xlightGray"
      borderRadius="large"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="lightGray"
    >
      <HStack space="small">
        <Hamburguer
          position="relative"
          width="16px"
          height="16px"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          isOpen={isOpen}
        >
          <Box
            height="2px"
            width="100%"
            backgroundColor="purple"
            transition="all 200ms ease"
            position="relative"
          />
          <Box
            height="2px"
            width="100%"
            backgroundColor="purple"
            transition="all 200ms ease"
            position="relative"
          />
          <Box
            height="2px"
            width="100%"
            backgroundColor="purple"
            transition="all 200ms ease"
            position="relative"
          />
        </Hamburguer>
      </HStack>
    </Box>
  );
};

const Hamburguer = styled(Box)`
  ${({ isOpen }) =>
    isOpen &&
    css`
      > div {
        &:first-child {
          transform: rotate(45deg);
          top: 5px;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: rotate(-45deg);
          bottom: 5px;
        }
      }
    `}
`;

Toggler.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default Toggler;
