import ProfileImage from '../../assets/images/user-profile.svg';
import { Box, HStack, Label, VStack } from '../SimpleUI';
import React from 'react';
import useAuthRecoil from '../../hooks/useAuthRecoil';
import useDesignUtils from '../../hooks/useDesignUtils';

const IsLoggedIn = () => {
  const { isMobile } = useDesignUtils();

  const {
    auth: { user },
  } = useAuthRecoil();

  return (
    <HStack alignItems="center" direction={isMobile ? 'reverse' : undefined}>
      <VStack space="xsmall">
        <Label tag="p" fontWeight="regular">
          Hola, {user.name}
        </Label>
      </VStack>
      <Box
        tag="img"
        src={ProfileImage}
        alt={`Perfil de ${user.name}`}
        className="image-cover"
        height="32px"
        width="32px"
        marginX="small"
        borderRadius="small"
      />
    </HStack>
  );
};

export default IsLoggedIn;
