import NavItem from './NavItem';
import { Box, HStack, Copy } from '../SimpleUI';
import PropTypes from 'prop-types';
import React from 'react';
import { HiOutlineLogout } from 'react-icons/hi';
import { theme } from '../../theme';
import { useHistory } from 'react-router-dom';
import useProfileTabs from '../../hooks/useProfileTabs';

const AuthNavbar = ({ onToggle, handleLogout }) => {
  const history = useHistory();
  const { toggleTab } = useProfileTabs();

  return (
    <Box marginTop="large" onClick={handleLogout}>
      <HStack space="small" alignItems="center" justifyContent="center">
        <HiOutlineLogout size="18" color={theme.colors.gray} />
        <Copy>Cerrar sesión</Copy>
      </HStack>
    </Box>
  );
};

AuthNavbar.propTypes = {
  onToggle: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default AuthNavbar;
