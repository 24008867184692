import React from 'react';
import PropTypes from 'prop-types';
import { use100vh } from 'react-div-100vh';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import LogoImage from '../assets/images/logo.png';
import useAuthRecoil from '../hooks/useAuthRecoil';
import useDesignUtils from '../hooks/useDesignUtils';
import AuthNavbar from './Navbar/AuthNavbar';
import MenuDropdown from './Navbar/Dropdown';
import IsLoggedIn from './Navbar/IsLoggedIn';
import NavItem from './Navbar/NavItem';
import Notifications from './Navbar/Notifications';
import Toggler from './Navbar/Toggler';
import useProfileTabs from '../hooks/useProfileTabs';
import {
  Box,
  Container,
  Copy,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  HStack,
  LightButton,
  NewTertiaryButton,
  Spacer,
  Wrap,
  WrapItem,
} from './SimpleUI';
import Menu1 from '../assets/icons/menu/1.panel.svg';
import Menu2 from '../assets/icons/menu/2.profile.svg';
import Menu3 from '../assets/icons/menu/3.products.svg';
import Menu4 from '../assets/icons/menu/4.us.svg';
import Menu5 from '../assets/icons/menu/5.faqs.svg';
import Menu6 from '../assets/icons/menu/6.academy.svg';
import Menu7 from '../assets/icons/menu/7.referrer.svg';
import Menu8 from '../assets/icons/menu/8.simulate.svg';
import Menu9 from '../assets/icons/menu/9.goals.png';
import Menu1Unlogged from '../assets/icons/menu/1.unlogged.svg';
import Menu2Unlogged from '../assets/icons/menu/2.unlogged.svg';
import ProfileOn from '../assets/icons/menu/profile_on.svg';
import ProfileOff from '../assets/icons/menu/profile_off.svg';

const NewDiv = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  position: relative;
  border-radius: 4px;
  margin-left: 8px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  line-height: 16px;
  background-color: #31d3ae;
  color: #1e2329;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: -3px;
    top: 2px;
    border-left: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid;
    border-right-color: #31d3ae;
  }
`;

const Navbar = ({ handleLogout, notificationIsOpen }) => {
  const height = use100vh();
  const navbarHeight = height - (notificationIsOpen ? 110 : 60);
  const { isMobile } = useDesignUtils();
  const history = useHistory();
  const [on, onToggle] = useToggle();
  const [dropdown, onToggleDropdown] = useToggle(false);
  const { activeTab, toggleTab } = useProfileTabs();
  const {
    auth: { isAuth, user },
  } = useAuthRecoil();

  const NAV_ITEMS = [
    {
      id: 1,
      to: '/mi-perfil/inversiones',
      label: isAuth ? 'Mi Panel' : null,
      exact: true,
      icon: Menu2,
    },
    {
      id: 3,
      to: '/proyectos-inmobiliarios',
      label: 'Unidades',
      exact: true,
      icon: Menu3,
    },
  ];

  const location = useLocation();

  const handleLogin = async (url) => {
    if (on) {
      await onToggle(false);
      await history.push(url);
    }
  };

  return (
    <Box
      tag="nav"
      boxShadow="medium"
      paddingY="xsmall"
      height="60px"
      display="flex"
      alignItems="center"
      role="navigation"
      backgroundColor="white"
    >
      <Container spaceY="none">
        <HStack alignItems="center">
          <HStack
            tag="section"
            alignItems="center"
            space={{ mobile: 'none', desktop: 'large' }}
            style={{ minWidth: '160px' }}
          >
            {isAuth && isMobile && on ? (
              <IsLoggedIn />
            ) : (
              <Link to="/">
                <Box
                  tag="img"
                  src={LogoImage}
                  alt={`${process.env.REACT_APP_CUSTOM_CONFIG_BRAND_NAME}`}
                  width="100%"
                  maxWidth="160px"
                />
              </Link>
            )}
          </HStack>
          {isMobile ? (
            <Box
              position="fixed"
              overflowY="scroll"
              height={`${navbarHeight}px`}
              width="100%"
              left="0"
              top={notificationIsOpen ? '110px' : '62px'}
              display="flex"
              flexDirection="column"
              zIndex="20"
              backgroundColor="white"
              borderColor="transparent"
              borderColorRight="lightGray"
              borderWidth="1px"
              borderStyle="solid"
              paddingX={{ mobile: 'medium', desktop: 'small' }}
              paddingY="medium"
              transition="all 300ms cubic-bezier(0.81, 0.08, 0.32, 1.04)"
              transform={`translateX(${on === true ? '0%' : '-100%'})`}
            >
              <Box
                tag="ul"
                margin="none"
                padding="none"
                display="flex"
                flexDirection="column"
                width="100%"
                listStyle="none"
                borderColor="transparent"
                borderColorBottom={isAuth && 'lightGray'}
                borderWidth="1px"
                borderStyle="solid"
              >
                {isMobile && (
                  <Box textAlign="center" paddingBottom="medium">
                    <Box tag="img" src={isAuth ? ProfileOn : ProfileOff} />
                    <Copy fontWeight="bold" color="purple">
                      {isAuth
                        ? `¡Hola, ${user.name}!`
                        : 'Aún no iniciás sesión'}
                    </Copy>
                  </Box>
                )}
                {NAV_ITEMS.map((item) =>
                  item.id === 1 ? (
                    <Box tag="li" width="100%" key={item.id}>
                      <Box
                        display="flex"
                        alignItems="center"
                        height="60px"
                        paddingLeft="large"
                      >
                        <HStack
                          space="medium"
                          alignItems={item.subLinks ? 'baseline' : 'center'}
                        >
                          <Box
                            tag="img"
                            src={isAuth ? item?.icon : item.unlogged?.icon}
                            width="20px"
                          />
                          <NavItem
                            to={isAuth ? item?.to : item.unlogged?.to}
                            exact={item.exact}
                            onClick={onToggle}
                            rest={{ url: item.url, fontWeight: 'bold' }}
                            icon={item?.icon}
                            color="red"
                          >
                            {isAuth || (isAuth && isMobile)
                              ? item?.label
                              : item.unlogged?.label}
                          </NavItem>
                        </HStack>
                      </Box>
                      <Box
                        borderStyle="solid"
                        borderWidth="0.1px"
                        borderColorX="transparent"
                        borderColorTop="transparent"
                        borderColorBottom="_midGray"
                      />
                    </Box>
                  ) : (
                    <Box key={item.id}>
                      <Box
                        display="flex"
                        alignItems="center"
                        height="60px"
                        paddingLeft="large"
                      >
                        <Box tag="li" width="100%">
                          <HStack
                            space="medium"
                            alignItems={item.subLinks ? 'baseline' : 'center'}
                          >
                            <Box tag="img" src={item?.icon} width="20px" />
                            {!item.url ? (
                              <>
                                <NavItem
                                  to={item.to}
                                  exact={item.exact}
                                  onClick={onToggle}
                                  subLinks={item.subLinks}
                                  rest={{ url: item.url }}
                                >
                                  {item.label}
                                </NavItem>
                                {item.id === 9 && (
                                  <NewDiv className="pulse-effect">
                                    Nuevo
                                  </NewDiv>
                                )}
                              </>
                            ) : (
                              <AnchorMobile
                                href={item.url}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                              >
                                {item.label}
                              </AnchorMobile>
                            )}
                          </HStack>
                        </Box>
                      </Box>
                      <Box
                        borderStyle="solid"
                        borderWidth="0.1px"
                        borderColorX="transparent"
                        borderColorTop="transparent"
                        borderColorBottom="_midGray"
                      />
                    </Box>
                  ),
                )}
              </Box>
              {isAuth && (
                <AuthNavbar onToggle={onToggle} handleLogout={handleLogout} />
              )}
            </Box>
          ) : (
            <Box width="100%" style={{ marginRight: '3%' }}>
              <Wrap justifyContent="flex-end">
                {NAV_ITEMS.map(
                  (item) =>
                    item.id !== 8 &&
                    item.id !== 2 && (
                      <WrapItem key={item.id} marginX="small">
                        {item.id === 1 ? (
                          isAuth && (
                            <NavItem
                              to={item.to}
                              exact={item.exact}
                              onClick={onToggle}
                              rest={{ url: item.url }}
                            >
                              {item.label}
                            </NavItem>
                          )
                        ) : (
                          <>
                            <NavItem
                              to={item.to}
                              exact={item.exact}
                              onClick={item.onClick}
                              subLinks={item.subLinks}
                            >
                              {item.label}
                            </NavItem>
                            {item.id === 9 && (
                              <NewDiv className="pulse-effect">Nuevo</NewDiv>
                            )}
                          </>
                        )}
                      </WrapItem>
                    ),
                )}
              </Wrap>
            </Box>
          )}

          <Spacer />

          {/* RIGHT SIDE */}
          {isMobile ? (
            <HStack alignItems="center" space="medium">
              {isAuth && <Notifications />}
              {!isAuth && (
                <Box onClick={() => history.push('/cuenta/crear-cuenta')}>
                  <Copy color="_green" fontWeight="medium">
                    Empezar
                  </Copy>
                </Box>
              )}
              <Toggler isOpen={on} onClick={onToggle} />
            </HStack>
          ) : (
            <Box tag="section" display="flex" alignItems="center">
              {isAuth ? (
                <HStack alignItems="center" space="medium">
                  <Notifications />
                  <Box height="25px" width="2px" backgroundColor="lightGray" />
                  <Dropdown isOpen={dropdown} toggle={onToggleDropdown}>
                    <DropdownToggle>
                      <IsLoggedIn />
                    </DropdownToggle>
                    <DropdownMenu>
                      <MenuDropdown
                        handleLogout={handleLogout}
                        toggle={onToggleDropdown}
                      />
                    </DropdownMenu>
                  </Dropdown>
                </HStack>
              ) : (
                <HStack space="small" alignItems="center">
                  <CustomButtonWrapper width="120px">
                    <LightButton
                      type="button"
                      onClick={() => history.push('/cuenta')}
                      size="full"
                      paddingY="xsmall"
                    >
                      <Copy
                        fontWeight="bold"
                        fontSize="smallCopy"
                        color="purple"
                      >
                        Ingresar
                      </Copy>
                    </LightButton>
                  </CustomButtonWrapper>
                  <Box width="150px">
                    <NewTertiaryButton
                      type="button"
                      onClick={() =>
                        history.push(
                          `${'/cuenta/crear-cuenta'.concat(location.search)}`,
                        )
                      }
                      size="full"
                      fontSize="smallCopy"
                      color="aneloRed"
                    >
                      Crear cuenta
                    </NewTertiaryButton>
                  </Box>
                </HStack>
              )}
            </Box>
          )}
        </HStack>
      </Container>
    </Box>
  );
};

const CustomButtonWrapper = styled(Box)`
  button {
    color: ${({ theme }) => theme.colors.purple};
  }
`;

const AnchorMobile = styled.a`
  text-decoration: none;
  font-weight: 400;
  font-size: 1.125rem;
  color: #181623;
`;

Navbar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  notificationIsOpen: PropTypes.bool,
};

export default Navbar;
