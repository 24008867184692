import React from 'react';
import {
  Box,
  VStack,
  Container,
  SmallCopy,
  Heading,
  PrimaryButton,
  Wrap,
  WrapItem,
  WhiteOutline,
} from './SimpleUI';
import styled from 'styled-components';
import { Cell, Grid } from '@mollycule/lattice';
import { useHistory, Link } from 'react-router-dom';
import FooterNav from './FooterNav';
import useAuthRecoil from '../hooks/useAuthRecoil';
import useDesignUtils from '../hooks/useDesignUtils';
import VHMobileStack from './VHMobileStack';
import useMixpanelEvents from '../hooks/useMixpanelEvents';
import LogoImage from '../assets/images/logo.png';

const Footer = () => {
  const history = useHistory();
  const { isMobile } = useDesignUtils();
  const mixpanel = useMixpanelEvents();
  const isMetasUrl = history.location.pathname === '/metas';

  const {
    auth: { isAuth },
  } = useAuthRecoil();

  return (
    <Box tag="footer" backgroundColor="white" paddingY="xxlarge">
      <Container>
        <VStack space="large">
          <VStack space="medium">
            <FooterSeparator />
            <FooterInfo>
              <VHMobileStack alignItems="center" space="large">
                <VStack space="medium">
                  <SmallCopy textAlign={'center'}>
                    <Box
                      tag="img"
                      src={LogoImage}
                      width="100%"
                      height="auto"
                      maxWidth="170px"
                    />
                  </SmallCopy>
                </VStack>

                <VStack space="medium">
                  <SmallCopy
                    color="black"
                    fontWeight="bold"
                    textAlign={'center'}
                  >
                    ¿DÓNDE NOS ENCONTRAMOS?
                  </SmallCopy>
                  <SmallCopy color="black" textAlign={'center'}>
                    {`${process.env.REACT_APP_CUSTOM_CONFIG_ADDRESS}`}
                  </SmallCopy>
                  <SmallCopy color="black" textAlign={'center'}>
                    <Box
                      tag="a"
                      href={`mailto:${process.env.REACT_APP_CUSTOM_CONFIG_EMAIL}`}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      display="inline-block"
                    >
                      {`${process.env.REACT_APP_CUSTOM_CONFIG_EMAIL}`}
                    </Box>
                  </SmallCopy>
                  <SmallCopy color="black" textAlign={'center'}>
                    {`WhatsApp: ${process.env.REACT_APP_CUSTOM_CONFIG_WHATSAPP}`}
                  </SmallCopy>
                </VStack>
              </VHMobileStack>
            </FooterInfo>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

const FooterSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  // opacity: 0.7;
  height: 2px;
  width: 100%;
`;

const FooterInfo = styled.div`
  p {
    //opacity: 0.5;
  }
`;

export default Footer;
