import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader-spinner';
import { theme } from '../../theme';

const Spinner = ({
  width = 50,
  height = 50,
  color = process.env.REACT_APP_CUSTOM_CONFIG_COLOR_PRIMARY,
}) => {
  return <Loader type="TailSpin" color={color} height={height} width={width} />;
};

Spinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Spinner;
