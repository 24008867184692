import React from 'react';
import PropTypes from 'prop-types';
import HStack from '../Stack/HStack';
import Box from '../Box';

// This component only returns a event.target.value cuz this is a custom radio component
const Radio = ({
  children,
  value,
  onChange,
  checked,
  disabled,
  name,
  tag = 'label',
}) => {
  const cursorType = disabled ? 'not-allowed' : 'pointer';

  const handleSelect = () => {
    if (!disabled) {
      onChange({
        target: {
          value: value,
        },
      });
    }
  };

  return (
    <Box
      tag={tag}
      position="relative"
      opacity={disabled && '0.5'}
      cursor={cursorType}
      onClick={handleSelect}
    >
      <HStack alignItems="center">
        <Box
          id="cb_term"
          tag="input"
          type="radio"
          value={value}
          width="1px"
          height="1px"
          position="absolute"
          overflow="hidden"
          visibility="hidden"
          opacity="0"
          ariaChecked={checked}
          name={name}
        />
        <Box
          tag="div"
          width="20px"
          height="20px"
          borderStyle="solid"
          borderWidth="1px"
          borderColor="_darkGray"
          borderRadius="full"
          backgroundColor={checked ? 'white' : 'transparent'}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleSelect}
          cursor={cursorType}
        >
          {checked && (
            <Box
              tag="span"
              width="12px"
              height="12px"
              backgroundColor="purple"
              borderRadius="full"
            />
          )}
        </Box>
        <Box tag="span" marginLeft="small">
          {children}
        </Box>
      </HStack>
    </Box>
  );
};

Radio.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.any,
  disabled: PropTypes.bool,
  tag: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default React.memo(Radio);
