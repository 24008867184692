import React from 'react';
import PropTypes from 'prop-types';
import { MdFlag } from 'react-icons/md';
import Box from './Box';

const ProgressBar = ({
  backgroundColor = 'green',
  progress = 10,
  showFlag = false,
}) => {
  return (
    <Box position="relative">
      <Box
        display="flex"
        height="15px"
        overflow="hidden"
        borderRadius="large"
        backgroundColor="_midGray"
      >
        <Box
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
          width={`${progress}%`}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          overflow="hidden"
          color="white"
          textAlign="center"
          backgroundColor={backgroundColor}
          transition="all 0.5ms ease"
        />
      </Box>
      {showFlag && (
        <Box
          position="absolute"
          right="0"
          top="50%"
          transform="translateY(-50%)"
        >
          <MdFlag color="#000" size={20} />
        </Box>
      )}
    </Box>
  );
};

ProgressBar.defaultProps = {
  backgroundColor: 'green',
};

ProgressBar.propTypes = {
  progress: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  showFlag: PropTypes.bool,
};

export default ProgressBar;
