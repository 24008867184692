import { useRecoilState } from 'recoil';
import { profileTabsState } from '../recoil/profiletabs';

const useFiltersRecoil = () => {
  const [activeTab, setProfileTab] = useRecoilState(profileTabsState);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setProfileTab(tab);
  };

  return {
    activeTab,
    toggleTab,
  };
};

export default useFiltersRecoil;
