import React from 'react';
import PropTypes from 'prop-types';
import Box from './Box';

const Container = ({
  tag,
  children,
  verticalSpace,
  backgroundColor = '#fff',
  paddingX = 'medium',
}) => {
  return (
    <Box
      tag={tag}
      maxWidth={{ mobile: '100%', desktop: '1400px' }}
      width="100%"
      marginX="auto"
      paddingX={paddingX}
      marginY={verticalSpace}
      backgroundColor={backgroundColor}
    >
      {children}
    </Box>
  );
};

Container.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node.isRequired,
  verticalSpace: PropTypes.string,
  backgroundColor: PropTypes.string,
  paddingX: PropTypes.string,
};

export default Container;
